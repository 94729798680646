<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div class="d-flex">
              <router-link
                  :to="{
                        name: 'agency.local-searches',
                      }"
                  class="btn btn-link mr-5 text-success font-weight-bold d-flex align-items-center"
              >
                <Feather class="icon-sm" type="chevrons-left" /> Back
              </router-link>
              <div class="form-inline">
                <label>
                  Show&nbsp;
                </label>
                <b-form-select
                    v-model="datatable.queries.per_page"
                    class="ml-1 mr-1"
                    size="sm"
                    :options="[10, 15, 25, 50, 100]"
                    @change="getBusinesses"
                ></b-form-select>
              </div>
              <div class="form-inline ml-3">
                <label>
                  Status Filter
                </label>
                <b-form-select
                    v-model="datatable.queries.status"
                    class="ml-1 mr-1"
                    size="sm"
                    :options="['Lead','Mailed','Scanned', 'All']"
                    @change="getBusinesses"
                ></b-form-select>
              </div>
            </div>

          </div>
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_filter text-md-right">
              <vue-json-to-csv :json-data="leadsEmpty" :csv-title="csvTitle()" :labels="csvLabels">
                <button class="btn btn-dark-blue btn-sm mr-3"><Feather class="icon-xs" type="download" /> Export</button>
              </vue-json-to-csv>
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                    v-model="datatable.queries.search"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
              <button
                  class="ml-1 btn btn-dark-blue btn-sm"
                  @click="getBusinesses"
              >
                <i class="uil uil-search-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="card rounded-lg">
          <div class="card-body">
            <template v-if="!loadingBusinesses">
              <div class="font-weight-bold mb-3">Campaign Results</div>
              <div class="table-responsive mb-0 customer-table">
                <div class="tablerounededCorner">
                <b-table
                  :items="leads"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :per-page="datatable.queries.per_page"
                  data-export-types="['csv']"
                  :no-local-sorting="true"
                  :striped="true"
                  thead-class="border-0"
                  class="roundedTable"
                  @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(rating)="data">
                    <div class="d-flex">
                      <div>{{data.item.rating}}</div>
                      <div><Feather :stroke-width="1" fill="gold" class="icon-xs" type="star" /></div>
                      <div>({{data.item.review_count > 0 ? data.item.review_count : 0}})</div>
                    </div>
                  </template>
                  <template v-slot:cell(status)="data">
                    <span class="font-weight-bold" :style="'color:' + statusColor(data.item.status)">{{data.item.status}}</span>
                  </template>
                  <template v-slot:cell(connected)="data">
                    <span v-if="data.item.location" class="text-success font-size-20"><i class="uil uil-check" /></span>
                    <span v-else class="text-danger font-size-20"><i class="uil uil-times" /></span>
                  </template>
                  <template v-slot:cell(actions)="data">
                    <a :href="data.item.google_maps_url" class="mr-1" target="_blank"><img style="max-height: 30px" src="https://review-link.sfo3.cdn.digitaloceanspaces.com/Icon.png"></a>
                    <router-link
                      :to="{
                        name: 'agency.campaign-lead',
                        // eslint-disable-next-line vue/camelcase
                        params: { id: data.item.search_id, lead: data.item.id },
                      }"
                      class="btn btn-success btn-sm ml-1"
                    >
                      View
                    </router-link>
                    <b-button
                      variant="link"
                      title="Delete"
                      size="lg"
                      class="btn-delete py-0"
                      :disabled="loadingRemove === data.item.id"
                      @click="remove(data.item)"
                    >
                      <b-spinner v-if="loadingRemove === data.item.id" small />
                      <i v-else class="uil uil-trash-alt"></i>
                    </b-button>
                  </template>
                </b-table>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalLeads }} Contacts
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalLeads"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showDeleteModal" title="Delete Customer">
      <p v-if="selectedBusiness"
        >Are you sure you want to delete business "{{
          selectedBusiness.business_name
        }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleRemove">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv'
export default {
  components:{
    VueJsonToCsv
  },
  data() {
    return {
      json_export_labels:{

      },
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
          { key: 'small_date', label: 'Date', sortable: false },
          { key: 'short_business_name', label: 'Business', sortable: false },
          { key: 'full_name', label: 'Name', sortable: false },
          { key: 'email', label: 'Email', sortable: false },
          { key: 'phone_number', label: 'Phone', sortable: false },
          { key: 'short_scanned_date', label: 'Scan Date', sortable: false },
          { key: 'short_lead_date', label: 'Opt-in Date', sortable: false },
          { key: 'rating', label: 'Reviews', sortable: false },
          { key: 'status', label: 'Status', sortable: false },
          { key: 'actions', label: 'G Link', class:'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'All',
          search: null,
          sort_by: 'is_lead',
          sort_desc: true,
        },
      },
      csvLabels:{
        first_name: { title: 'first_name' },
        last_name: { title: 'last_name' },
        email: { title: 'email' },
        phone_number: { title: 'phone_number' },
        business_name: { title: 'business_name' },
        business_phone: { title: 'business_phone' },
        business_website: { title: 'business_website' },
        address: { title: 'address' },
        city: { title: 'city' },
        state: { title: 'state' },
        zip: { title: 'zip' },
        country: { title: 'country' },
        place_id: { title: 'place_id' },
        types: { title: 'type' },
        rating: { title: 'rating' },
        review_count: { title: 'review_count' },
        single_review_text: {title: 'single_review_text'},
        single_review_image: {title: 'single_review_image'},
        single_review_rating: {title: 'single_review_rating'},
        google_maps_url: { title: 'Google Maps URL' },
        lat: { title: 'Latitude' },
        lng: { title: 'Longitude' },
        status: { title: 'Status' },
        unique_id: { title: 'Unique ID' },
        scanned: { title: 'Scanned' },
        mailed: { title: 'Mailed' },
        is_lead: { title: 'Is lead' },
        created_at: { title: 'Created at' },
        updated_at: { title: 'Updated at' },
        full_name: { title: 'Full name' },
        primary_category: { title: 'Primary category' },
        pretty_phone: { title: 'Pretty phone' },
      },
      showDeleteModal: false,
      selectedBusiness: null,
      loadingBusinesses: false,
      loadingImpersonate: false,
      loadingRemove: null,
      createIsBusy:false,
      showCreateLeadModal:false,
      campaign_id:null
    }
  },

  computed: {
    leads() {
      return this.$store.getters['leads/all'] || []
    },
    leadsEmpty() {
      return this.leads.map(lead => {
        return Object.keys(lead).reduce((acc, key) => {
          acc[key] = (lead[key] === null || lead[key] === 'null') ? '' : lead[key];
          return acc;
        }, {});
      });
    },
    totalLeads() {
      return this.$store.getters['leads/total']
    },

    reload() {
      return this.$store.getters['leads/reload']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    routeName(){
      return this.$route.name;
    },
  },

  watch: {
    reload: (val) => {
      console.log(val)
    },
  },

  created() {
    this.campaign_id = this.$route.params.id;
    this.$store.dispatch('leads/setCurrentPage', 'index')
  },

  mounted() {
    this.getBusinesses();

    if (this.user.agency.is_new) {
      this.showWelcomeModal = true

      this.$store.dispatch('agency/toggleIsNew', this.user.agency)
    }

    if(window.location.search.includes('create_lead')){
      this.showCreateLeadModal = true;
      let query = Object.assign({}, this.$route.query);
      delete query.create_lead;
      if(query.hasOwnProperty('business_name')) {
        this.widgetDefaultText = decodeURI(query.business_name);
        delete query.business_name;
      }
      this.$router.replace({ query });
    }
  },

  methods: {
    prospectCreated(business){
      this.showCreateLeadModal = false;
      this.createIsBusy = false;
      this.$router.push({
        name: 'agency.businesses.edit',
        params: { id: business.id },
      })
    },
    csvTitle(){
      return 'lead-machine-campaign-export-'+Date.now()
    },
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getBusinesses()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getBusinesses()
    },

    getBusinesses() {
      this.loadingBusinesses = true
      this.$store
        .dispatch('leads/getAll', {
          id:this.campaign_id,
          queries: this.datatable.queries,
          page: 'leads_index',
        })
        .then((res) => {
          this.loadingBusinesses = false
        })
        .catch(() => {
          this.loadingBusinesses = false
        })
    },
    remove(business) {
      this.showDeleteModal = true
      this.selectedBusiness = business
    },

    handleRemove() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedBusiness.id
      this.$store
        .dispatch('leads/delete', this.selectedBusiness.id)
        .then(() => {
          this.loadingRemove = null
          this.getBusinesses()
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },
  },
}
</script>
<style lang="scss" scoped>
  .status-filter{
    text-transform: capitalize;
  }
</style>
<style>
.pac-container.pac-logo {
  z-index: 10000000;
}
.customer-table td.actions {min-width:200px; max-width: 200px;}
</style>
